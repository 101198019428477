// URL Routes definition

module.exports = {
    Front: {
		devops: `devops.smartscreening.de`,
		staging: `staging.goshaba.io`,
		prod: `app.goshaba.io`
    },
    Back: {
		devops: `node.devops.smartscreening.de`,
		staging: `node.staging.goshaba.io`,
		prod: `node.app.goshaba.io`
    },
    'rh-assets-service': {
		devops: `node.devops.smartscreening.de/api/asset`,
		staging: `node.staging.goshaba.io/api/asset`,
		prod: `node.app.goshaba.io/api/asset`
    },
    'rh-axe-scoring-service': {
		devops: `node.devops.smartscreening.de/api/axe/scoring`,
		staging: `node.staging.goshaba.io/api/axe/scoring`,
		prod: `node.app.goshaba.io/api/axe/scoring`
    },
    'rh-minigame-scoring-service': {
		devops: `node.devops.smartscreening.de/api/minigame/scoring`,
		staging: `node.staging.goshaba.io/api/minigame/scoring`,
		prod: `node.app.goshaba.io/api/minigame/scoring`
    },
    'rh-pdf-service': {
		devops: `node.devops.smartscreening.de/api/pdf`,
		staging: `node.staging.goshaba.io/api/pdf`,
		prod: `node.app.goshaba.io/api/pdf`
    },
    'rh-questionnaire-scoring-service': {
		devops: `node.devops.smartscreening.de/api/questionnaire/scoring`,
		staging: `node.staging.goshaba.io/api/questionnaire/scoring`,
		prod: `node.app.goshaba.io/api/questionnaire/scoring`
    }
};
