import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import {TableBody} from "@material-ui/core";
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import axios from "axios";
import { css } from '@emotion/core';
import { CircleLoader } from 'react-spinners';
import './Table.css';

const routes = require('./config/routes.js');

const useStyles = makeStyles(theme => ({
    root: {
        width: '95%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
        backgroundColor: '#f7f7f7',
    },
}));

const override = css`
    display: block;
    margin: 0 auto;
    border-color: blue;
`;

function SimpleTable() {
    const classes = useStyles();
    const [services, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        let data = [];
        for (let appName in routes) {
            let row = [appName];
            console.log(JSON.stringify(row));
            for (let env in routes[appName]) {
                try {
                    const result = await axios('https://' + routes[appName][env] + '/version');
                    row.push(result.data);
                } catch (e) {
                    row.push("Network");
                }
            }
            data.push(row);
        }
        setData(data);
        console.log('data', data);
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    let names = ["Front", "Back", "rh-assets-service", "rh-axe-scoring-service", "rh-minigame-scoring-service", "rh-pdf-service", "rh-questionnaire-scoring-service"];
    let envs = ["Devops", "Staging", "Prod"];
    // let Spinerlist = [ RingLoader, PacmanLoader, CircleLoader];
    // let rand = Math.floor(Math.random() * Spinerlist.length);

    return (
        <Paper className={classes.root}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Environment</TableCell>
                        <TableCell align="center"><a href={"https://devops.smartscreening.de/"} target="_blank"
                                                    rel="noopener noreferrer">Devops</a></TableCell>
                        <TableCell align="center"><a href={"https://staging.goshaba.io/"} target="_blank"
                                                    rel="noopener noreferrer">Staging</a></TableCell>
                        <TableCell align="center"><a href={"https://app.goshaba.io/"} target="_blank"
                                                    rel="noopener noreferrer">Production</a></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {services.length ?
                            services.map((service, index) => {
                                    return (
                                        <TableRow key={index}>
                                            {service.map((version, index) =>
                                            <TableCell key={index} align={index === 0 ? "left" : "center"}>{version}</TableCell>)
                                            }
                                        </TableRow>)
                                        ;}) :
                                    names.map((service, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell key={index} align="left">{service}</TableCell>
                                                {envs.map((env, index) =>
                                                    <TableCell key={index} align="center">
                                                        {<CircleLoader
                                                            css={override}
                                                            sizeUnit={"px"}
                                                            size={15}
                                                            color={'#181a22'}
                                                            loading={loading}
                                                        />}
                                                    </TableCell>)
                                                }
                                            </TableRow>);
                                    })}
                </TableBody>
            </Table>
        </Paper>
    );
}

export default SimpleTable;
